.book-status-reserved-text.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  margin-left: 0.3em;
  color: #00609a;
}

.reserved-book-status {
  padding: 0.2em 0.7em 0.3em 0.375em;
  margin-bottom: 0px;
  display: flex;
  background-color: #f5fbff;
  max-width: auto;
  border-radius: 8px;
}
