.book-status-rented-text.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  margin-left: 0.3em;
  color: #ea592a;
}

.book-status-rented-by-you {
  padding: 0.2em 0.7em 0.3em 0.375em;
  margin-bottom: 0.5em;
  display: flex;
  background-color: #fef6f4;
  width: 6em;
  border-radius: 8px;
}
