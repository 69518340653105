.form-cancel-button.MuiButton-root {
  background-color: transparent;
  border-radius: 20px;
  margin: 0 1em;
  border: 1px solid #eeeeee;
  width: 132px;
  height: 40px;
}
.form-cancel-button.MuiButton-root:hover {
  border: 1px solid #eeeeee;
}
.form-cancel-button.MuiButton-root:hover::after {
  border: 1px solid #eeeeee;
}

.form-cancel-button-text {
  color: black;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@media only screen and (max-width: 860px) {
  .form-cancel-button.MuiButton-root {
    background-color: transparent;
    border-radius: 20px;
    margin: 0.7em 0 1.5em 0;
    border: 0;
    width: 100%;
    height: 40px;
  }
}
