.cancel-button.MuiButtonBase-root.MuiButton-root {
  background-color: white;
  color: black;
  border-radius: 20px;
  border-color: #eeeeee;
  border: 1px;
  height: 40px;
}

.confirm-button.MuiButtonBase-root.MuiButton-root {
  background-color: #00609a;
  color: white;
  border-radius: 20px;
  border-color: #eeeeee;
  border-width: 1px;
  height: 40px;
}

.delete-button.MuiButtonBase-root.MuiButton-root {
  background-color: #d94844;
  color: white;
  border-radius: 20px;
  border-color: #eeeeee;
  border-width: 1px;
  height: 40px;
}

.modal-action-button-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@media only screen and (max-width: 800px) {
  .delete-button.MuiButtonBase-root.MuiButton-root {
    width: 50%;
  }

  .cancel-button.MuiButtonBase-root.MuiButton-root {
    width: 50%;
  }

  .confirm-button.MuiButtonBase-root.MuiButton-root {
    width: 50%;
  }
}
