.book-collection-main-div {
  min-height: 81.5vh;
  position: relative;
}

.all-books {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(204px, 14.2em));
  column-gap: 1vw;
  row-gap: 2vw;
  flex-direction: column;
  padding-bottom: 110px;
}

.pagination-div {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pagination-box {
  width: 140px;
  height: 96px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.pagination-progress {
  width: 140px;
  height: 34px;
  padding: 0px;
}

.pagination-progress p {
  width: 110px;
  height: 18px;
  text-align: center;
  color: #686868;
  margin: 0px 15px 12px 15px;

  font-size: 12px;
  line-height: 18px;
}

.pagination-progress span {
  background-color: #c4c4c4;
  height: 1px;
  border-radius: 2px;
}

.MuiLinearProgress-root span {
  background-color: #000000;
  width: 100%;
  border-radius: 2px;
}

.MuiButtonBase-root.MuiButton-root {
  height: 37px;
  width: 140px;
  color: black;
  border: 1px solid #000000;
  box-sizing: border-box;

  text-transform: none;

  box-shadow: 0px 0px 0px 2px #f7f7f9;
  border-radius: 20px;

  margin: 24px 0px;
}

.book-collection-main-div .MuiButtonBase-root.MuiButton-root:hover {
  border: 1px solid #000000;
}

@media only screen and (max-width: 800px) {
  .all-books {
    grid-template-columns: repeat(auto-fit, minmax(18em, 20em));
    column-gap: 3vw;
  }

  .all-books .book-box {
    width: 100%;
    height: 180px;
  }

  .all-books .image-box-clickable {
    position: absolute;
    margin: 0;
    transform: scale(0.7);
    transform-origin: top left;
  }

  .all-books .star-rating {
    position: absolute;
    margin: 26px 0 0 136px;
    transform: scale(0.9);
  }

  .all-books .text-box {
    position: absolute;
    margin: 16px 0 0 140px;
  }

  .all-books .book-status-homepage {
    position: absolute;
    margin: 128px 0 0 142px;
    transform: scale(0.9);
  }
}
