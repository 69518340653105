.cover-image {
    height: 80px;
    width: 56px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;}

.cover-image img {
  object-fit: cover;
  height: 80px;
  width: 56px;
  border-radius: 8px;
}

.title-row{
    max-width: 70px;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.status {
    max-width: 100px;
    white-space: nowrap;
}

.status-div {
    max-width: 6em;
}

.buttons-row.MuiTableCell-root {
    padding-right: 3%;
    white-space: nowrap;
}

.edit-button.MuiButtonBase-root.MuiButton-root {
    background-color: #00609a;
    color: white;
    border-radius: 20px ;
    border-color: #eeeeee;
    border-width: 1px;
    height: 40px;
    width: auto;
}

.edit-icon {
    color: white;
}

.delete-btn.MuiButtonBase-root.MuiButton-root {
    background-color: #ea592a;
    color: white;
    border-radius: 20px;
    border-color: #eeeeee;
    border-width: 1px;
    height: 40px;
    width: auto;
    margin-left: 10%;
}