.submit-button.MuiButton-root {
  background-color: #00609a;
  border-radius: 20px;
  margin: 0;
  border: 0;
  width: 132px;
  height: 40px;
}

.submit-button.MuiButton-root:hover {
  background-color: #00609a;
  border: 0;
}

.submit-button.MuiButton-root:disabled {
  background-color: #eeeeee;
}

.submit-button-text {
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.submit-button-text-disabled {
  color: #cccccc;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@media only screen and (max-width: 860px) {
  .submit-button.MuiButton-root {
    background-color: #00609a;
    border-radius: 20px;
    margin: 1em 0 0 0;
    border: 0;
    width: 100%;
    height: 40px;
  }
}
