.landing-container {
  display: flex;
  background-color: white;
  padding-left: 5.56%;
  padding-right: 5.56%;
}

.img-div {
  flex: 3;
  padding: 3vh 0;
}

.landing-img {
  height: 86vh;
}

.content-div {
  display: flex;
  flex-direction: column;
  flex: 5;
  text-align: center;
  justify-content: center;
}
.content-div img {
  height: 32px;
}

.content-div .small-font {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.content-div .large-font {
  font-size: 28px;
  line-height: 42.17px;
  font-weight: 300;
}

.content-div .landing-text {
  margin-top: 4%;
  margin-bottom: 3%;
}

.landing-text .welcome-text {
  margin-bottom: 0.5rem;
}

.landing-text .description {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 820px) {
  .landing-container {
    height: calc(100vh - 64px);
  }
  .img-div {
    display: none;
  }
  .content-div {
    justify-content: start;
    margin-top: 23vh;
  }
  .content-div > img {
    height: 42px;
  }
  .content-div .landing-text {
    margin-top: 25px;
    margin-bottom: 22px;
  }
  .landing-text .welcome-text {
    margin-bottom: 2vh;
  }
  .welcome-text .small-font {
    margin-bottom: 4px;
  }
}
