.book-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 86vh;
  padding: 3.5vh 4.5vw;
  background-color: #f7f7f9;
}

.book-details-left {
  width: 14.5em;
  height: 86vh;
}

.bd-cover {
  display: flex;
  flex-direction: column;
  height: 16em;
  padding-top: 0;
  align-items: left;
}

.rating-div {
  width: 10em;
  display: flex;
  justify-content: center;
  transform: scale(1.1);
  transform-origin: left;
}

.book-details-right {
  width: calc(80vw - 12.5em);
}

.book-details-status {
  width: 80%;
  height: 30px;
  margin: 0.6em 0;
  display: flex;
  gap: 0.5em;
}

.book-details-status > div > div {
  transform: scale(1.1);
  margin-left: 0.4em;
}

.bd-text-box {
  position: relative;
  width: 100%;
  padding: 0;
}

.MuiTypography-root.bd-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.MuiTypography-root.bd-author {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0.6em;
}

.bd-text-box > button {
  position: absolute;
  width: 216px;
  right: 0;
  bottom: 5.3vh;
  cursor: pointer;
}

.description-section {
  margin-top: 1em;
}

.categories-section {
  margin-top: 1.2em;
}

.book-details-right > .review-section {
  padding: 0;
}

.book-details-right .review-collection .separator-line {
  display: none;
}

.book-details-right > .review-section .title .MuiTypography-root {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 1vh;
}

.book-details-right .review-card-div {
  justify-content: left;
  padding-bottom: 30px;
  position: relative;
}

.book-details-right .review-card-info {
  width: 60vw;
  flex-direction: column;
  flex-wrap: wrap;
}

.book-details-right .review-card-info-header {
  flex-wrap: wrap;
  width: 12em;
}

.book-details-right .star-rating {
  margin-top: 0.5em;
  transform: scale(0.85);
}

.book-details-right .review-card-comment {
  width: auto;
}

.book-details-right .review-card-recommended {
  width: auto;
}

.book-details-right .review-card-avatar {
  width: auto;
  margin-right: 2em;
}

.book-details-right .review-action-container {
  position: absolute;
  width: 100px;
  padding: 0;
  top: 15%;
  right: 1%;
}

@media only screen and (max-width: 800px) {
  .book-details {
    display: block;
    min-height: 93.5vh;
    padding: 0;
  }

  .book-details-left {
    width: auto;
    height: auto;
  }

  .bd-cover {
    display: flex;
    flex-direction: column;
    height: 17em;
    padding-top: 3vh;
    align-items: center;
  }

  .rating-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3.5vh;
    margin-left: 0;
    transform: scale(1);
  }

  .book-details-right {
    width: auto;
    height: auto;
  }

  .bd-text-box {
    width: 80%;
    padding: 0.7em 2em 2em 2em;
  }

  .book-details-status {
    width: auto;
    height: auto;
    margin: 0;
  }

  .description-section {
    display: none;
  }

  .categories-section {
    display: none;
  }

  .bd-text-box > button {
    position: relative;
    width: 100%;
    bottom: 0;
    display: block;
  }

  .book-details-right .review-card-div {
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 10px;
  }

  .book-details-right .review-card-avatar {
    width: 13%;
    margin-right: 1.2em;
  }

  .book-details-right .review-card-info-header {
    width: auto;
  }

  .book-details-right .review-collection .separator-line {
    display: block;
  }

  .book-details-right .review-section > div > .separator-line {
    margin: 0.7em 0 0.5em 0;
  }

  .book-details-right > .review-section {
    padding: 0.7em 2em 0em 2em;
  }

  .book-details-status > div > div {
    margin-left: 2.2em;
    transform: scale(1);
  }

  .book-details-right .review-action-container {
    position: relative;
    width: auto;
    padding: 0;
    top: 0;
    right: 0;
    margin: 3% 0 0 22%;
  }
}
