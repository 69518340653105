.image-box {
  height: 240px;
  width: 176px;
  position: absolute;
}

.image-box-clickable {
  height: 240px;
  width: 176px;
  position: absolute;
  cursor: pointer;
}

.main-box {
  height: 212px;
  width: 148px;
  margin: 14px 0px 14px 28px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
}

.main-box img {
  object-fit: cover;
  height: 212px;
  width: 148px;
  border-radius: 8px;
}

.shadow-box {
  height: 240px;
  width: 130px;
  margin-right: 46px;
  position: absolute;
  background: #eeeeee;
  border-radius: 16px;
}
