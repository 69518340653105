.MuiPaper-root.MuiDialog-paper {
  margin: 16px;
  border-radius: 8px;
  padding-top: 10px;
}

.action-container.MuiDialogActions-root {
  padding: 0 1.5em;
  min-width: 270px;
}

.modal-description.MuiTypography-root {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.modal-content {
  padding: 10px;
}

.modal-title-container {
  display: flex;
  justify-content: space-between;
}

.modal-title-container .modal-title.MuiTypography-root {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.close-icon-modal {
  cursor: pointer;
}
