.book-box {
  height: 386px;
  width: 204px;
}

.image-box {
  height: 240px;
  width: 176px;
  position: absolute;
}

.main-box {
  height: 212px;
  width: 148px;
  margin: 14px 0px 14px 28px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
}

.main-box img {
  object-fit: cover;
  height: 212px;
  width: 148px;
  border-radius: 8px;
}

.shadow-box {
  height: 240px;
  width: 130px;
  margin-right: 46px;
  position: absolute;
  background: #eeeeee;
  border-radius: 16px;
}

.book-box > .star-rating {
  margin-top: 252px;
  position: absolute;
  transform: scale(0.9);
}

.text-box {
  height: 132px;
  width: 204px;
  margin-top: 240px;
  position: absolute;
  z-index: 10;
}

.title-box p {
  max-height: 36px;
  width: 204px;
  margin-top: 44px;
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: start;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  color: #000000;
}

.title-clickable {
  cursor: pointer;
}

.author-box p {
  height: 18px;
  width: 204px;
  margin-top: 7px;
  position: relative;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;

  display: flex;
  align-items: center;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: #000000;
}

.book-status-homepage {
  position: absolute;
  margin-top: 350px;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #ffffff;
  color: black;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  box-shadow: 0px 1px 4px rgba(59, 59, 59, 0.1);
}
