.topic-container {
  position: sticky;
  top: 4em;
  z-index: 1290;
  box-shadow: 0px 1px 4px rgba(59, 59, 59, 0.1);
}

.inner-topic-container {
  padding-left: 5.56%;
  padding-right: 5.56%;
  background-color: #f7f7f9;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topic-buttons {
  margin-left: 1vw;
}

.topic-container .side-text {
  font-size: 14px;
}

.topic-container .clear-all-button {
  font-weight: 400;
  font-size: 14px;
  color: #00609a;
  margin-left: 1vw;
  cursor: pointer;
}

.topic-container .topic-button {
  margin-right: 10px;
  width: auto;
  border-color: lightgray;
}

.topic-container .topic-button.MuiButton-root:hover {
  border-color: lightgray;
}

.topic-container .topic-button.MuiButton-root:hover::after {
  border: 0;
}

.topic-buttons .MuiTypography-root {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.topic-buttons .selected-button {
  margin-right: 10px;
  width: auto;
  color: #00609a;
  border-color: #00609a;
}

.inner-topic-container .topic-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .topic-container {
    overflow-x: scroll;
    overflow-y: auto;
    background-color: #f7f7f9;
  }

  .topic-container .side-text {
    padding-top: 1em;
  }

  .inner-topic-container .topic-buttons-container {
    white-space: nowrap;
  }

  .inner-topic-container {
    display: block;
    width: 100%;
  }

  .topic-buttons {
    margin-left: 0;
  }

  .topic-container .clear-all-button {
    margin-bottom: 1.3em;
    min-width: 20vw;
  }

  .topic-buttons .selected-button {
    margin-top: 0.5em;
  }

  .topic-container .topic-button {
    margin-top: 0.5em;
  }
}
