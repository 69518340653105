.header-container .action-container {
  display: flex;
  flex-direction: row;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 800px) {
  .header-container {
    justify-content: flex-start;
  }
}
