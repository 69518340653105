.navbar {
  height: 64px;
}

.MuiPaper-root.MuiAppBar-root {
  position: sticky;
  background-color: white;
  z-index: 1299;
  box-shadow: 0px 1px 4px rgba(59, 59, 59, 0.1);
}

.navbar-div {
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 5.56%;
}

.navbar-search {
  min-width: 30%;
  max-height: 40px;
  display: flex;
  align-self: center;
}

.search-screen-toggle {
  min-width: 32%;
  min-height: 24px;
  display: flex;
  align-self: center;
  position: relative;
  border-right: 1px solid #cccccc;
}

.search-screen-toggle svg {
  color: black;
  right: 10px;
  position: absolute;
}

.search-screen-mobile {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 1400;
}

.search-screen-mobile .navbar-search {
  min-width: 100%;
  min-height: 64px;
  margin: 15px 0;
}

.search-screen-mobile .navbar-search-back svg {
  color: black;
}

.navbar-search-back {
  position: absolute;
  top: 22px;
  left: 18px;
  max-width: 24px;
}

.search-screen-mobile .navbar-search .MuiInputBase-root.MuiOutlinedInput-root {
  background-color: white;
}

.search-screen-mobile
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 0;
  box-shadow: none;
}

.right-side {
  display: flex;
  align-self: center;
}

.add-book-button-wrapper {
  align-self: center;
  position: relative;
  padding-right: 25px;
}

@media only screen and (max-width: 800px) {
  .add-book-button-wrapper {
    padding-right: 0;
  }
}
