.main {
    margin-left: 300px;
    margin-top: 10px;
    margin-right: 40px;
    padding-bottom: 200px;
  }

.main-mobile {
  margin-left: 100px;
  margin-top: 10px;
  margin-right: 40px;
  padding-bottom: 200px;
}

.button-div {
  padding-bottom: 1%;
  padding-right: 1%;
  display: flex;
  justify-content: end;
}

.button-div-mobile {
  padding-bottom: 1%;
  padding-left: 1%;
}

.block {
  margin-left: 270px;
  box-shadow: 0px 1px 4px rgba(59, 59, 59, 0.1);
  display: flex;
  justify-content:space-between; 
}

.search-div {
  padding: 12px 50px;
  width: 300px;
}

.button {
  padding: 20px 50px;
}