.MuiTypography-root.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.MuiTypography-root.modal-title-description {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-top: 0.8em;
}

.sidebar-modal-title-container {
  display: flex;
  justify-content: space-between;
}

.close-icon {
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .close-icon {
    display: none;
  }
}
