.topic-select-menu {
  width: 100%;
  margin-top: 0.7em;
  border: 1px solid #cccccc;
}

.MuiModal-root.MuiPopover-root.MuiMenu-root {
  z-index: 1400;
}

.topic-select .MuiBox-root {
  width: auto;
  height: auto;
}

.topic-select .MuiInputBase-root.MuiOutlinedInput-root {
  height: auto;
  min-height: 5em;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  cursor: pointer;
}

.topic-select
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: none;
}
