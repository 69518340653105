.MuiPaper-root.MuiAlert-root {
  color: white;
  background-color: black;
  border-radius: 0.75em;
}

.MuiPaper-root.MuiAlert-root .description-message {
  margin-top: 1em;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.MuiPaper-root.MuiAlert-root .inner-message {
  margin-top: 0.3em;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}
