.navbar-info {
    user-select: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  
  .logo {
    height: 32px;
    margin: 16px 32px 16px 0;
  }
  
  .navbar-text {
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000000;
  }
  
  .navbar-company p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  
  .navbar-title p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }