.title {
  /* All books (72) */
  /*position: absolute;*/
  position: relative;
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 1.5em;
}
