.records-dialog.MuiDialog-root .MuiDialog-container {
  align-items: flex-end;
  background-color: #f7f7f933;
}

.records-dialog .MuiPaper-root.MuiDialog-paper {
  width: 100%;
  margin: 0;
}

.records-dialog-item {
  margin-bottom: 10px;
}
