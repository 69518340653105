.credentials-image {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;
}

.credentials-image-navbar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.email {
  font-size: 12px;
}
.user-name {
  align-self: flex-end;
  font-size: 14px;
}

.account-avatar {
  align-self: center;
  padding-left: 25px;
  border-left: 1px solid #cccccc;
}

.MuiBox-root {
  width: 40px;
  height: 40px;
  padding: 0px;
}

.MuiButtonBase-root.MuiIconButton-root {
  width: 40px;
  height: 40px;
  padding: 0px;
}

.MuiList-root.MuiMenu-list {
  padding: 0px;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  margin-top: 12px;
  border-radius: 8px;
}

#account-menu .MuiButtonBase-root.MuiMenuItem-root:first-child {
  background-color: #eeeeee;
  color: #000000;
  padding: 16px;
  pointer-events: none;
}

@media only screen and (max-width: 800px) {
  .account-avatar {
    padding-left: 0;
    border-left: none;
  }
}
